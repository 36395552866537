/**
 * Input Schema of BlockchainVersion
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

/* TODO: review generated schema */
export class InputBlockchainVersionSchema extends DefaultSchema {
  readonly name = 'InputBlockchainVersion'

  readonly fieldSet: FieldSet<BlockchainVersion> = {
    title: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 255,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    color: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'color',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    networkIdentifier: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 255,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    tagName: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 64,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
  }
}





































import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {InputBlockchainVersionSchema} from '@/schema/resource/BlockchainVersion/InputBlockchainVersionSchema'

@Component
export default class PersistBlockchainVersionView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputBlockchainVersionSchema()
  blockchainVersion = new BlockchainVersion()

  async created() {
    await this.populate()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.blockchainVersion.getBlockchainVersion(id)
    }

    this.$await.done('getBlockchainVersion')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.blockchainVersion.persistBlockchainVersion()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/blockchain-version/list')
  }
}
